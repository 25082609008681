$bootstrap-icons-font-dir: "/assets/lib/bootstrap-icons/font/fonts";

$kz-colors-screens: hsl(213, 50%, 38%);
$kz-colors-screens-dark: hsl(213, 50%, 30%);
$kz-colors-doors: hsl(343, 63%, 47%);
$kz-colors-doors-dark: hsl(343, 63%, 38%);
$kz-colors-alusys: hsl(93, 54%, 38%);
$kz-colors-alusys-dark: hsl(93, 54%, 28%);
$kz-colors-background: #e6e6e6;
// font:300 14px/26px Arial,Helvetica,Geneva,sans-serif

/*
font-weight: 300
font-size: 12px
line-height:16px
 */
//$warning-dark: chocolate;
$warning-dark: #F19A3E;
$font-family-sans-serif: Arial,Helvetica,Geneva,sans-serif;
//$font-family-sans-serif: times;
//$font-weight-base: 300;
//$font-size-base: 0.875rem;
//$line-height-base: 16px;

//$font-size-base:              2rem;

$body-bg: $kz-colors-background;
//$body-bg: blue;
$container-bg: #FFF;
//$container-bg: red;
$card-bg: $container-bg;
$list-group-bg: $container-bg;

/*$primary: $kz-colors-screens;
$success: $kz-colors-alusys;
$danger: $kz-colors-doors;*/

//$text-muted: orange;

//$enable-shadows:                              true;
//$enable-gradients:                            true;
//$enable

// no margin below labels in forms
$label-margin-bottom: 0;

$link-color: $kz-colors-screens;

$primary: $kz-colors-screens;
$success: $kz-colors-alusys;
$danger: $kz-colors-doors;