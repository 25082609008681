@import "bootstrap-styles";
//@import "kz";

/*
html,
body {
  height: 100%;
}

body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}
*/

/*
.form-signin {
  width: 100%;
  max-width: 430px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}*/

//main .container, main .container-fluid {
//  background: $container-bg;
//}

body.page-login {
  background: $white;
  form input.form-control:focus {
    border-color: #ced4da;
    box-shadow: none;
  }
  .btn-primary {
    background-color: #315D93;
    border-color: #315D93;
  }
  .btn-primary:focus, .btn-primary:hover, .btn-primary:active {
    background-color: rgba(49, 93, 147, .8);
    border-color: rgba(49, 93, 147, .8);
  }

  .btn-primary:not(:disabled):not(.disabled):focus, .btn-primary:not(:disabled):not(.disabled):hover, .btn-primary:not(:disabled):not(.disabled):active {
    background-color: rgba(49, 93, 147, .8);
    border-color: rgba(49, 93, 147, .8);
  }
  a {
    color: #315D93;
  }

  .login-tabs ul {
    text-align: center;
    list-style-type: none;
    margin:0;
    padding:0;
  }
  .login-tabs li {
    display: inline-block;
    padding:10px;
  }
  .login-tabs li.active {
    /*font-weight: bold;*/
    color:white;
    background: #315D93;
  }
  .login-tabs a {
    transition: all .2s ease-in-out
  }
  h2 {
    font-weight: bold;
    font-size: 1.8rem;
  }
  h3 {
    font-size: 1.6rem;
  }
  .login-row-products ul {
    padding-left:17px
  }
  .login-row-products h3 a {
    color: #315D93
  }
}

body > .feneko-background {
  position: fixed;
  height: 100%;
  width: 100%;
  background-image: url('/assets/img/group/lockscreen_normal.jpg');
  background-size: cover;
  background-position: center;
  //filter: blur(5px) brightness(75%);
  filter: blur(2px);
  opacity: .4;
  //filter: blur(3px);
  //opacity: .9;

  z-index: -1;
}

main .container {
  background-color: #fff;
}